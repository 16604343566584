<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 168.04 73.73"
    >
        <g
            id="Layer_2"
            data-name="Layer 2"
        >
            <g
                id="Layer_1-2"
                data-name="Layer 1"
            >
                <g
                    id="Group_17"
                    data-name="Group 17"
                >
                    <g
                        id="Group_16"
                        data-name="Group 16"
                    >
                        <rect
                            id="Rectangle_1"
                            data-name="Rectangle 1"
                            width="168.04"
                            height="73.73"
                            fill="#e50019"
                        />

                        <g
                            id="Group_15"
                            data-name="Group 15"
                        >
                            <g
                                id="Group_1"
                                data-name="Group 1"
                            >
                                <path
                                    id="Path_1"
                                    data-name="Path 1"
                                    d="M19.36,12.64V28.28H17V12.64H10.63V10.56h15v2.08Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_2"
                                    data-name="Path 2"
                                    d="M19.36,28.54H16.69V12.9H10.35V10.3H25.94v2.6H19.63V28.54ZM10.9,12.37h6.33V28h1.85V12.37h6.31V10.82H10.9Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_2"
                                data-name="Group 2"
                            >
                                <path
                                    id="Path_3"
                                    data-name="Path 3"
                                    d="M44.18,28.28V20.11H36.27v8.17H33.91V10.56h2.36V18h7.91v-7.4h2.4V28.28Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_4"
                                    data-name="Path 4"
                                    d="M46.58,28.54h-2.4v-.26h0v.26h-.27V20.37H36.54v8.17H33.62V10.3h2.92v7.4h7.37V10.3h2.94V28.54ZM46.3,28V10.82H44.45v7.4H36v-7.4H34.18V28H36V19.86h8.46V28Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_3"
                                data-name="Group 3"
                            >
                                <path
                                    id="Path_5"
                                    data-name="Path 5"
                                    d="M67.2,26.71a7.1,7.1,0,0,1-9.73,0c-2-1.88-2.26-4.45-2.26-7.28s.24-5.4,2.26-7.27a7.1,7.1,0,0,1,9.73,0c2,1.87,2.26,4.45,2.26,7.27S69.22,24.83,67.2,26.71Zm-1.63-13a4.55,4.55,0,0,0-6.43,0l0,0c-1.53,1.5-1.47,3.87-1.47,5.72s-.05,4.23,1.47,5.73a4.55,4.55,0,0,0,6.43,0l0,0c1.52-1.5,1.47-3.88,1.47-5.73s0-4.22-1.47-5.72Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_6"
                                    data-name="Path 6"
                                    d="M57.28,26.9c-2.12-2-2.35-4.64-2.35-7.46h0c0-2.84.23-5.5,2.35-7.46h0a7.2,7.2,0,0,1,5-2h0a7.28,7.28,0,0,1,5.06,2h0c2.11,2,2.33,4.63,2.34,7.46h0c0,2.83-.23,5.5-2.34,7.46h0a7.28,7.28,0,0,1-5,2h0a7.25,7.25,0,0,1-5.06-2Zm.38-14.55c-1.93,1.78-2.18,4.26-2.18,7.09h0c0,2.82.25,5.3,2.18,7.08h0a6.66,6.66,0,0,0,4.67,1.85h0A6.71,6.71,0,0,0,67,26.52h0l.19.19L67,26.52c1.94-1.79,2.18-4.26,2.18-7.08h0c0-2.83-.24-5.3-2.18-7.1h0a6.75,6.75,0,0,0-4.67-1.85h0a6.71,6.71,0,0,0-4.67,1.86Zm1.24,13c-1.53-1.51-1.55-3.75-1.55-5.54h0v-.36h0v-.38h0c0-1.78,0-4,1.55-5.53h0a4.77,4.77,0,0,1,3.43-1.43h0a4.75,4.75,0,0,1,3.43,1.43h0l-.2.18.2-.18c1.52,1.51,1.55,3.76,1.55,5.55h0v.36h0v.35h0c0,1.79,0,4-1.55,5.55h0a4.79,4.79,0,0,1-3.43,1.43h0a4.81,4.81,0,0,1-3.43-1.43Zm.39-11.45c-1.32,1.3-1.4,3.38-1.4,5.17h0v.38h0v.36h0c0,1.79.07,3.88,1.4,5.18h0a4.3,4.3,0,0,0,3,1.27h0a4.19,4.19,0,0,0,3-1.27h0c1.33-1.3,1.4-3.4,1.39-5.19h0v-.35h0v-.36h0c0-1.79-.07-3.89-1.39-5.19h0a4.28,4.28,0,0,0-3-1.27h0a4.28,4.28,0,0,0-3,1.27Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_4"
                                data-name="Group 4"
                            >
                                <path
                                    id="Path_7"
                                    data-name="Path 7"
                                    d="M88.71,28.28v-14l-3.23,8.6h-2.1l-3.24-8.55V28.28H77.88V10.56h2.89l3.71,9.9,3.63-9.9H91V28.28Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_8"
                                    data-name="Path 8"
                                    d="M91,28.54H88.71v-.26h0v.26h-.27V15.81l-2.77,7.36H83.18l-2.76-7.31V28.54H77.6V10.3H81l3.52,9.38,3.43-9.38h3.34V28.54ZM90.69,28V10.82h-2.4L84.47,21.25l-3.9-10.43H78.15V28h1.71V14.36l.53-.09,3.17,8.38h1.72l3.17-8.42.53.08V28Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_5"
                                data-name="Group 5"
                            >
                                <path
                                    id="Path_9"
                                    data-name="Path 9"
                                    d="M111.67,28.28l-1.55-4.57h-7.23l-1.52,4.57H99l6.13-17.72h2.84l6.15,17.72Zm-5.2-15.44-2.89,8.77h5.86Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M114.09,28.54h-2.42v-.26l.26-.08-.26.08v.26h-.2L109.92,24h-6.83l-1.52,4.57h-3l6.3-18.24h3.24l6.33,18.24ZM113.7,28l-6-17.2h-2.44l-6,17.2h1.82l1.52-4.57h7.63L111.86,28Zm-10.5-6.15,3-9.11.25-.79,3.36,9.9Zm.74-.52h5.12l-2.59-7.66Zm2.26-8.44.27-.08Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_6"
                                data-name="Group 6"
                            >
                                <path
                                    id="Path_11"
                                    data-name="Path 11"
                                    d="M132.05,28.28,124.59,14V28.28H122.3V10.56h2.92l7.44,14.32V10.56h2.26V28.28Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_12"
                                    data-name="Path 12"
                                    d="M134.92,28.54h-2.87v-.26l.25-.12-.25.12v.26h-.17l-7-13.4v13.4H122V10.3h3.36l7,13.46V10.3h2.8V28.54Zm-.28-.52V10.82h-1.71V24.88l-.52.12L125,10.82h-2.46V28h1.74V14l.52-.12L132.23,28Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_7"
                                data-name="Group 7"
                            >
                                <path
                                    id="Path_13"
                                    data-name="Path 13"
                                    d="M154.15,28.28,146.68,14V28.28h-2.29V10.56h2.92l7.44,14.32V10.56H157V28.28Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_14"
                                    data-name="Path 14"
                                    d="M157,28.54h-2.87v-.26l.25-.11-.25.11v.26H154l-7-13.4v13.4h-2.84V10.3h3.37l7,13.47V10.3h2.81V28.54Zm-.28-.52V10.82H155V24.88l-.51.12-7.37-14.18h-2.47V28h1.73V14l.52-.11L154.31,28Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_8"
                                data-name="Group 8"
                            >
                                <path
                                    id="Path_15"
                                    data-name="Path 15"
                                    d="M23.3,63.72l-1.55-4.57H14.52L13,63.72H10.61L16.73,46h2.84l6.15,17.72ZM18.1,48.27,15.21,57h5.86Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_16"
                                    data-name="Path 16"
                                    d="M25.72,64H23.3v-.27l.26-.07-.26.07V64h-.2L21.55,59.4H14.72L13.2,64h-3l6.3-18.25h3.24L26.1,64ZM11,63.46h1.81l1.53-4.58h7.62l1.55,4.58h1.84l-6-17.2H16.92l-6,17.2Zm3.85-6.16,3-9.11.26-.77,3.34,9.88Zm.75-.52h5.11L18.1,49.12Zm2.26-8.43.26-.08Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_9"
                                data-name="Group 9"
                            >
                                <path
                                    id="Path_17"
                                    data-name="Path 17"
                                    d="M45.55,62.29A9,9,0,0,1,35,62.37a5.73,5.73,0,0,1-1.95-4.25h2.45a3.9,3.9,0,0,0,1.29,2.8A5.19,5.19,0,0,0,40.3,62a4.93,4.93,0,0,0,3.41-1,2.55,2.55,0,0,0,.95-2.08c0-1.37-.87-2.52-3.07-2.85l-2.87-.42c-2.79-.4-4.92-1.88-4.92-4.7a4.72,4.72,0,0,1,1.58-3.5,6.91,6.91,0,0,1,4.81-1.75A7,7,0,0,1,45,47.32a5.27,5.27,0,0,1,1.76,3.82H44.29A3.41,3.41,0,0,0,43.4,49a4.17,4.17,0,0,0-3.23-1.25A4.15,4.15,0,0,0,36.93,49a2.65,2.65,0,0,0-.71,1.9c0,1.6,1.34,2.32,2.74,2.5,1,.12,2,.3,3,.45,3.1.42,5.12,2.22,5.12,5A4.52,4.52,0,0,1,45.55,62.29Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_18"
                                    data-name="Path 18"
                                    d="M34.82,62.55a5.93,5.93,0,0,1-2-4.43h0v-.27h3v.27A3.68,3.68,0,0,0,37,60.73h0a5,5,0,0,0,3.31,1h0a4.69,4.69,0,0,0,3.23-1h0a2.28,2.28,0,0,0,.86-1.88h0c0-1.26-.73-2.26-2.85-2.59h0l-2.86-.43c-2.85-.39-5.15-2-5.14-4.95h0a5,5,0,0,1,1.66-3.69h0a7.23,7.23,0,0,1,5-1.83h0a7.33,7.33,0,0,1,5,1.73h0a5.49,5.49,0,0,1,1.85,4h0v.27H44v-.24a3.21,3.21,0,0,0-.83-2h0a3.89,3.89,0,0,0-3-1.16h0a3.86,3.86,0,0,0-3,1.16h0a2.4,2.4,0,0,0-.65,1.73h0c0,1.43,1.16,2.05,2.5,2.24h0c1.06.12,2,.3,3,.45h0c3.19.42,5.37,2.35,5.36,5.28h0a4.83,4.83,0,0,1-1.64,3.64h0A8,8,0,0,1,40.3,64.3h0a8,8,0,0,1-5.47-1.75Zm.37-.38a7.48,7.48,0,0,0,5.1,1.61h0a7.48,7.48,0,0,0,5.07-1.68h0a4.27,4.27,0,0,0,1.46-3.26h0c0-2.67-1.87-4.34-4.89-4.77h0c-1-.15-2-.32-3-.45h0c-1.44-.16-3-1-3-2.75h0a3,3,0,0,1,.78-2.08h0a4.47,4.47,0,0,1,3.44-1.34h0a4.51,4.51,0,0,1,3.44,1.34h0a3.65,3.65,0,0,1,.94,2.09h1.9a5,5,0,0,0-1.66-3.38h0a6.84,6.84,0,0,0-4.6-1.58h0a6.77,6.77,0,0,0-4.63,1.68h0a4.52,4.52,0,0,0-1.49,3.32h0c0,2.67,2,4,4.69,4.44h0l2.86.42c2.3.32,3.33,1.62,3.3,3.11h0a2.81,2.81,0,0,1-1,2.27h0a5.2,5.2,0,0,1-3.6,1.09h0a5.46,5.46,0,0,1-3.69-1.15h0a4.26,4.26,0,0,1-1.35-2.73h-1.9a5.48,5.48,0,0,0,1.85,3.8Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_10"
                                data-name="Group 10"
                            >
                                <path
                                    id="Path_19"
                                    data-name="Path 19"
                                    d="M68.22,55.29a7,7,0,0,1-4.81,1.55H59v6.88H56.69V46h6.7a6.9,6.9,0,0,1,5,1.67,5.11,5.11,0,0,1,1.44,3.67A5.17,5.17,0,0,1,68.22,55.29ZM63.33,48H59v6.8h4.34c2.5,0,4-1.1,4-3.48S65.65,48,63.33,48Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_20"
                                    data-name="Path 20"
                                    d="M59,64H56.41V45.73h7a7.26,7.26,0,0,1,5.18,1.76h0a5.37,5.37,0,0,1,1.51,3.85h0a5.39,5.39,0,0,1-1.66,4.13h0a7.37,7.37,0,0,1-5,1.63H59.29V64ZM57,63.45h1.8V56.58h4.66A6.79,6.79,0,0,0,68,55.1h0a5,5,0,0,0,1.5-3.76h0a4.91,4.91,0,0,0-1.37-3.5h0a6.75,6.75,0,0,0-4.77-1.58H57v17.2Zm1.8-8.38V47.75h4.58v.52h-4v6.29h4.07c2.42,0,3.71-1,3.75-3.22h0c0-2.1-1.54-3-3.78-3.07h0v-.52c2.39,0,4.31,1.19,4.32,3.59h0c0,2.5-1.72,3.73-4.29,3.73Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_11"
                                data-name="Group 11"
                            >
                                <path
                                    id="Path_21"
                                    data-name="Path 21"
                                    d="M88.37,63.72V55.54H80.46v8.18H78.09V46h2.37v7.39h7.91V46h2.39V63.72Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_22"
                                    data-name="Path 22"
                                    d="M90.76,64H88.37v-.25h0V64H88.1V55.8H80.73V64H77.81V45.73h2.92v7.4H88.1v-7.4H91V64Zm-.28-.52V46.25H88.64v7.4H80.18v-7.4H78.36v17.2h1.82V55.28h8.46v8.17Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_12"
                                data-name="Group 12"
                            >
                                <path
                                    id="Path_23"
                                    data-name="Path 23"
                                    d="M111.67,63.72l-1.55-4.57h-7.23l-1.52,4.57H99L105.1,46h2.84l6.15,17.72Zm-5.2-15.45L103.58,57h5.86Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_24"
                                    data-name="Path 24"
                                    d="M114.09,64h-2.42v-.26l.26-.09-.26.09V64h-.2l-1.55-4.58h-6.83L101.57,64h-3l6.3-18.25h3.24L114.47,64Zm-.38-.53-6-17.19h-2.45L99.35,63.45h1.81l1.53-4.57h7.62l1.56,4.57ZM103.2,57.3l3-9.11.26-.77,3.35,9.88Zm.74-.52h5.12l-2.59-7.65Zm2.26-8.43.27-.08Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_13"
                                data-name="Group 13"
                            >
                                <path
                                    id="Path_25"
                                    data-name="Path 25"
                                    d="M124,63.72V46h2.33V61.64h9.63v2.08Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_26"
                                    data-name="Path 26"
                                    d="M124,64v-.26h0V64h-.28V45.73h2.9V61.38h9.62V64Zm11.69-.52V61.9H126V46.25h-1.79v17.2Z"
                                    fill="#fff"
                                />
                            </g>

                            <g
                                id="Group_14"
                                data-name="Group 14"
                            >
                                <path
                                    id="Path_27"
                                    data-name="Path 27"
                                    d="M151.91,48.07V63.72h-2.39V48.07h-6.33V46h15v2.07Z"
                                    fill="#fff"
                                />

                                <path
                                    id="Path_28"
                                    data-name="Path 28"
                                    d="M151.91,64h-2.66V48.33h-6.34V45.72H158.5v2.61h-6.3V64Zm-8.45-16.16h6.33V63.45h1.85V47.81H158V46.25H143.46Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>

                        <rect
                            id="Rectangle_2"
                            data-name="Rectangle 2"
                            x="10.73"
                            y="36.53"
                            width="15.13"
                            height="2.07"
                            fill="#fff"
                        />

                        <rect
                            id="Rectangle_3"
                            data-name="Rectangle 3"
                            x="32.74"
                            y="36.53"
                            width="15.13"
                            height="2.07"
                            fill="#fff"
                        />

                        <rect
                            id="Rectangle_4"
                            data-name="Rectangle 4"
                            x="54.76"
                            y="36.53"
                            width="15.13"
                            height="2.07"
                            fill="#fff"
                        />
                    </g>

                    <rect
                        id="Rectangle_5"
                        data-name="Rectangle 5"
                        x="76.78"
                        y="36.53"
                        width="15.13"
                        height="2.07"
                        fill="#fff"
                    />

                    <rect
                        id="Rectangle_6"
                        data-name="Rectangle 6"
                        x="98.8"
                        y="36.53"
                        width="15.13"
                        height="2.07"
                        fill="#fff"
                    />

                    <rect
                        id="Rectangle_7"
                        data-name="Rectangle 7"
                        x="120.81"
                        y="36.53"
                        width="15.13"
                        height="2.07"
                        fill="#fff"
                    />

                    <rect
                        id="Rectangle_8"
                        data-name="Rectangle 8"
                        x="142.83"
                        y="36.53"
                        width="15.13"
                        height="2.07"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
